import React, { Component, Suspense } from 'react';
import { LoadingSpinner } from 'adminlte-2-react';
import SnackbarProvider from 'react-simple-snackbar';
import LoginPage from './pages/Login';
import AuthProvider from './common/providers/AuthProvider';
import { RootContext, RootProvider } from './services/contexts/RootContext';

const AdminInterface = React.lazy(() => import('./components/Admin/Admin'));

class App extends Component {
  state = {};
  render() {
    return (
      <RootProvider>
        <RootContext.Consumer>
          {
            ({state}) => (
              <SnackbarProvider>
                <AuthProvider
                  LoginPage={LoginPage}
                  isAuth={!!state?.auth?.token}
                >
                  <Suspense
                    fallback={<LoadingSpinner />}
                  >
                    <AdminInterface />
                  </Suspense>
                </AuthProvider>
              </SnackbarProvider>
            )
          }
        </RootContext.Consumer>
      </RootProvider>
    );
  }
}

export default <App />;
