import React, {useContext, useState} from 'react';
import {useFormik} from 'formik';
import {
  Button, Inputs, Col, Row, Footer, LoadingSpinner
} from 'adminlte-2-react';
import {Card} from 'react-bootstrap';
import '../sass/LoginPage.scss';
import * as Yup from 'yup';
import {RootContext} from '../services/contexts/RootContext';
import {action} from '../services/contexts/utils';
import {AUTH_LOGIN} from '../services/contexts/RootActionTypes';
import {removeUnmountStorage} from "../utils/storage";

const {Text, ImportWrapper} = Inputs;

const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .required('Required'),
});

const LoginPage = ({history}) => {
  const [processing, setProcessing] = useState(false);
  const {api, dispatch, state} = useContext(RootContext);
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setErrors,
  } = useFormik({
    validationSchema: SignupSchema,
    validateOnChange: false,
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
    onSubmit: async ({email, password}) => {
      setProcessing(true);
      const response = await api.login({email, password}).catch(() => false);
      setProcessing(false);
      if (response && response.status === 201) {
        const data = await response.json();
        if (data.role === 1 || data?.role === 2) {
          dispatch(action(AUTH_LOGIN, {auth: data, remember: values.remember,}));
          return;
        }
      }
      setErrors({
        email: 'Access denied',
        password: ''
      });
      removeUnmountStorage();
    },
  });
  return (
    <div className="login-page">
      <div className="page-container content-wrapper">
        <Col md={4} sm={10}>
          <Card bg="light" className="login-form-container">
            {processing && <LoadingSpinner/>}
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <Text
                    id="email"
                    help={errors.email || '‎'}
                    type={errors.email ? 'error' : ''}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelPosition="above"
                    name="email"
                    placeholder="Email"
                    label="Email"
                  />
                  <Text
                    id="password"
                    help={errors.password || '‎'}
                    type={errors.password ? 'error' : ''}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelPosition="above"
                    name="password"
                    placeholder="Password"
                    label="Password"
                    inputType="password"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <label className="d-flex">
                    <input
                      className="mr-3"
                      type="checkbox"
                      id="remember"
                      value={values.remember}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="remember"
                    />
                    Remember Me
                  </label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    className="login-form__submit"
                    size="md"
                    text="Sign in"
                    onClick={handleSubmit}
                    type="info"
                  />
                </Col>
              </Row>
            </form>
          </Card>
        </Col>
      </div>
      <Footer/>
    </div>
  );
};

export default LoginPage;
