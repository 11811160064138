export const updateSheetData = (host, token, type, data) => fetch(
  `${host}/admin/sheets/${type}`,
  {
    method: 'PUT',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
    body: data
  },
);

export const getAPIKey = (host, token) => fetch(
  `${host}/admin/sheets/api-key`,
  {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${token}` },
  },
);

export const updateAPIKey = (host, token, data) => fetch(
  `${host}/admin/sheets/api-key`,
  {
    method: 'PUT',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
    body: data
  },
);

export const syncSheet = (host, token, listName) => fetch(
  `${host}/admin/sheets/sync/${listName}`,
  {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
  },
);

export const getSheetData = (host, token, listName) => fetch(
  `${host}/admin/sheets/${listName}`,
  {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${token}` },
  },
);
