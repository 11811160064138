import React, {useReducer, createContext, useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import API from '../api';
import { action, reducer } from './utils';
import RootActions from './RootActions';
import { AUTH_LOGOUT } from './RootActionTypes';

const RootContext = createContext();

const getAuthInit = () => {
  const authString = localStorage.getItem('__auth__') || sessionStorage.getItem('__auth__');
  let auth;
  try {
    auth = authString && JSON.parse(authString);
  } catch (e) {
    auth = {};
  }
  return auth;
}

const initialState = {
  auth: getAuthInit(),
  user: null,
  alert: null
};

function RootProvider({ children }) {
  const [state, dispatch] = useReducer(reducer.bind(RootActions(initialState)), initialState);

  const api = useRef(new API()).current;
  useEffect(() => {
    api.addEventListener(((event, data) => {
      if ([401].includes(data.status)) {
        dispatch(action(AUTH_LOGOUT));
      }
    }))
  }, [api]);

  return (
    <RootContext.Provider
      value={{ state, dispatch, api }}
    >
      { children }
    </RootContext.Provider>
  );
}

RootProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export {
  RootProvider,
  RootContext,
};
