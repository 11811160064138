import { DEFAULT_ACTION } from './RootActionTypes';

/**
 *
 * @param type
 * @param {*?} payload
 * @return {{payload?, type}}
 */
export function action(type, payload) {
  return { type, payload };
}

/**
 * @param state
 * @param action
 */
export function reducer(state, action) {
  const actions = this.actions;
  const { type = DEFAULT_ACTION, payload } = action;
  if (actions.hasOwnProperty(type) && typeof actions[type] === 'function') {
    return actions[type](state, payload);
  }
  return state;
}

export function asyncDispatch({ state, dispatch, _action = {} } = {}) {
  const asyncActions = this.actions;
  const { type, payload } = _action;
  const isActionExist = asyncActions.hasOwnProperty(type);
  const isFunction = isActionExist && typeof asyncActions[type] === 'function';
  const isAsync = isActionExist && asyncActions[type].constructor.name === 'AsyncFunction';
  if (isAsync) {
    return asyncActions[type](payload)
  }
  if (isFunction) {
    dispatch(_action)
  }
}
