export const actionDomainsAPI = (host, token, ...args) => {
    const {page = 1, limit = 25, color, category, search, sort} = args[0];
    return fetch(
        `${host}/admin/domains?page=${page}&limit=${limit}&sort=${sort}${!!color ? `&ratingColor=${color}` : ''}${!!category ? `&hasCategory=${category}` : ''}${!!search ? `&search=${search}` : ''}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        },
    );
}

export const getDomain = (host, token, id) => {
    return fetch(
        `${host}/admin/domains/${id}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        },
    );
}

export const getDomainList = (host, token, list) => {
    return fetch(
        `${host}/admin/sheets/${list}`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        },
    );
}

export const syncCurrentList = (host, token, list) => {
    return fetch(
        `${host}/admin/sheets/sync/${list}`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        },
    );
}

export const syncCurrentCategory = (host, token, id) => {
    return fetch(
        `${host}/admin/shop/categories/${id}/sync`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
        },
    );
}

export const getRuleForDomain = (host, token, domainId)=> {
    return fetch(`${host}/admin/domains/${domainId}/rules`,{
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    });
}

export const changeRuleForDomain = (host, token, body, domainId)=> {
    console.log(body, domainId)
    return fetch(`${host}/admin/domains/${domainId}/rules`,{
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    });
}

export const checkCurrentDomain = (host, token, url)=>{
    return fetch(
        `${host}/check-url`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            body: JSON.stringify(url)
        },
    );
}
export const checkCacheDomain = (host, token, url)=>{
    return fetch(
        `${host}/check-url/cache`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            body: JSON.stringify(url)
        },
    );
}

export const getNotifications = (host, token ,id)=>{
    return fetch(
        `${host}/admin/domains/${id}/notification`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        },
    );
}

export const postNotifications = (host, token, body = {})=>{
    return fetch(
        `${host}/admin/domains/notification`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            body: JSON.stringify(body)
        },
    );
}

export const getApiDataPoints = (host, token, domainId = 0)=>{
    return fetch(
        `${host}/admin/domains/${domainId}/datapoints`,
        {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        },
    );
}

export const postApiDataPoints = (host, token, domainId = 0, body = {})=>{
    return fetch(
        `${host}/admin/domains/${domainId}/datapoints`,
        {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            body: JSON.stringify(body)
        },
    );
}

export const getDomainShop = (host, token, domainId = 0,) => {
  return fetch(
    `${host}/admin/domains/${domainId}/shop`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    },
  );
}

export const getDomainsLabels = (host, token) => {
  return fetch(
    `${host}/admin/domains/labels`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
    },
  );
}
export const getDomainsImages = (host, token, domainId = 0) => {
    return fetch(
      `${host}/admin/domains/${domainId}/images`,
      {
          method: 'GET',
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      },
    );
}
export const postDomainsImages = (host, token, domainId= 0, body = {})=>{
    return fetch(
      `${host}/admin/domains/${domainId}/images`,
      {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
          body: JSON.stringify(body)
      },
    );
}
export const postDomainShop = (host, token, domainId= 0, body = {})=>{
  return fetch(
    `${host}/admin/domains/${domainId}/shop`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      body: JSON.stringify(body)
    },
  );
}
