export const getProducts = (host, token, currentPage, tableSize, searchValue, filters, sortBy) => {
  const setSortColumn = (column) => {
    switch (column) {
      case 'price':
        return 'prices.price';
      case 'domainColor':
        return 'domain.ratingColor';
      case 'checkStatus':
        return 'product.checkStatus';
      case 'isFixed':
        return 'product.isManuallyEdited';
      case 'hasCoupons':
        return 'coupons';
      case 'similarProducts':
        return 'children';
      case 'hasFailsToParseData':
        return 'product.failedScrapAttempts';
      case 'viewedBy':
        return 'viewedBy';
      case 'viewedAt':
        return 'viewedAt';
      default:
        return `product.${column}`;
    }
  }
  const filterValues = {
    hasFailsToParseDataYes: filters[0].value,
    hasFailsToParseDataNo: filters[1].value,
    isFixedYes: filters[2].value,
    isFixedNo: filters[3].value,
    hasAppliedCouponsYes: filters[4].value,
    hasAppliedCouponsNo: filters[5].value,
    hasMatchesYes: filters[6].value,
    hasMatchesNo: filters[7].value,
    isEditedByAdminYes: filters[8].value,
    isEditedByAdminNo: filters[9].value,
    discountsCashback: filters[10].value,
    discountsGift: filters[11].value,
    dateFrom: filters[12].value,
    dateBefore: filters[13].value,
    processingStatus: filters[14].value,
    researcherEmail: filters[15].value,
    checkStatus: filters[16].value,
    closeToDeadlineYes: filters[17].value,
    closeToDeadlineNo: filters[18].value,
    autoCheckedBy: filters[19].value,
    hasSuspiciousYes: filters[20].value,
    hasSuspiciousNo: filters[21].value,
    hasInactiveYes: filters[22].value,
    hasInactiveNo: filters[23].value,
  };

  const queryParams = [
    { param: 'hasFailsToParseData', isBoolean: true, values: [1, -1] },
    { param: 'isFixed', isBoolean: true, values: [1, -1] },
    { param: 'hasAppliedCoupons', isBoolean: true, values: [1, -1] },
    { param: 'hasMatches', isBoolean: true, values: [1, -1] },
    { param: 'isFixedByAdmin', isBoolean: true, values: [1, -1] },
    { param: 'hasCashback', isBoolean: true, values: ['cashback', 'giftCards'] },
    { param: 'closeToDeadline', isBoolean: true, values: ['true', 'false'] },
    { param: 'dateFrom', data: filterValues.dateFrom, valueFn: (value) => new Date(value).toLocaleDateString('fr-CA') },
    { param: 'dateBefore', data: filterValues.dateBefore, valueFn: (value) => new Date(value).toLocaleDateString('fr-CA') },
    { param: 'status', data: '', valueFn: (value) => value !== 'null' },
    { param: 'search', data: searchValue},
    { param: 'researcherEmail', data: filterValues.researcherEmail},
    { param: 'checkStatus', data: filterValues.checkStatus},
    { param: 'autoCheckedBy', data: filterValues.autoCheckedBy},
    { param: 'hasSuspicious', isBoolean: true, values: [1, -1]},
    { param: 'hasInactive', isBoolean: true, values: [1, -1]},
  ];

  let link = `${host}/admin/products?page=${currentPage}&limit=${tableSize}&sortColumn=${setSortColumn(sortBy[0].column)}&sortDirection=${sortBy[0].direction?.toUpperCase() ?? 'ASC'}`;

  for (let { param, isBoolean, values, valueFn, data } of queryParams) {
    let newParam = param;
    if (isBoolean) {
      let positiveValue = null;
      let negativeValue = null;
      if (newParam === 'isFixedByAdmin') {newParam = 'isEditedByAdmin'}
      if (filterValues[`${newParam}Yes`] === 1 && filterValues[`${newParam}No`] === -1) {}
      else {
        positiveValue = filterValues[`${newParam}Yes`] === 1 ? values[0] : null;
        negativeValue = filterValues[`${newParam}No`] === -1 ? values[1] : null;
      }
      const value = filterValues[newParam] ?? positiveValue ?? negativeValue;
      if (value) {
        link += `&${newParam}=${valueFn?.(value) ?? value}`;
      }
    } else if (data) {
      link += `&${newParam}=${valueFn?.(data) ?? data}`
    }
  }
  return fetch(link, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
  });
};
export const initialProductID = (host, token, id) => fetch(`${host}/admin/products/${id}`, {
  headers: {
    'Authorization': `Bearer ${token}`
  },
});

export const getMatchedProducts = (host, token, id, sortColumn, sortDir, search) => {
  let link = `${host}/admin/products/${id}/similar-products?`;

  if(sortColumn){
    link += `&sortColumn=${sortColumn}`
  }
  if(sortDir){
    link += `&sortDirection=${sortDir}`
  }
  if(search){
    link += `&search=${search}`
  }

  return fetch(link, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })
};

export const createNewSimilarProduct = (host, token, id, body) => fetch(`${host}/admin/products/${id}/similar-products`, {
  method: 'POST',
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-type': 'Application/json'
  },
  body: JSON.stringify(body),
});
export const removeProductID = (host, token, id) => fetch(`${host}/admin/products/${id}`, {
  method: 'DELETE',
  headers: {
    'Authorization': `Bearer ${token}`
  },
});
export const updateInitialProductID = (host, token, id, body) => fetch(`${host}/admin/products/${id}`, {
  method: 'PUT',
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-type': 'Application/json'
  },
  body: JSON.stringify(body),
});
export const updateAppliedCoupons = (host, token, id, body) => fetch(`${host}/admin/products/${id}/coupons`, {
  method: 'POST',
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-type': 'Application/json'
  },
  body: JSON.stringify(body),
});
export const refreshProducts = (host, token) => fetch(`${host}/admin/products/refresh`, {
  method: 'POST',
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-type': 'Application/json'
  },
});
export const getPrices = (host, token, id, page) => fetch(`${host}/admin/products/${id}/prices?page=${page}`,{
  headers: {
    'Authorization': `Bearer ${token}`
  },
})
export const updatePrice = (host, token, id, priceId, body) => fetch(`${host}/admin/products/${id}/prices/${priceId}`, {
  method: 'PUT',
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-type': 'Application/json'
  },
  body: JSON.stringify(body),
});
