import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RootContext } from '../../services/contexts/RootContext';

function AuthProvider({ children, isAuth, LoginPage, history }) {
  const { api, state } = useContext(RootContext);
  if (!isAuth && LoginPage) {
    return <LoginPage />;
  }
  api.token = state.auth?.token;
  return children;
}

AuthProvider.defaultProps = {
  isAuth: false,
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
  isAuth: PropTypes.bool,
  LoginPage: PropTypes.elementType.isRequired,
};

export default AuthProvider;
