export const getBlogList = (host, token, obj) => {
  return fetch(
    // `${host}/admin/blogs?take=${obj.take}&skip=${obj.skip}`,
    `${host}/admin/blogs`,
    {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
    },
  );
}

export const getArticle = (host, token, id, method, data) => {
  return fetch(
    `${host}/admin/blogs/${id}`,
    {
      method: `${method}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      body: data,
    },
  );
}

export const createArticle = (host, token, method, data) => {
  return fetch(
    `${host}/admin/blogs`,
    {
      method: `${method}`,
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      body: data,
    },
  );
}

export const postImage = (host, token, data, type) => {
  return fetch(
    `${host}/admin/blogs/image?type=${type}`,
    {
      method: "POST",
      headers: {'Authorization': `Bearer ${token}`},
      body: data,
    },
  );
}
