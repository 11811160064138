export const getGiftCards = (host, token, page = 1, limit = 50, search = '') => {
  let link = `${host}/admin/domains/gift-card?page=${page}&limit=${limit}`;
  if(search){
    link += `&search=${search}`;
  }
  // if(filter){
  //   link += `&filter=${filter}`;
  // }
  return fetch(link, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`}
    },
  );
}

export const editGiftCard = (host, token, id, domain) => {
  console.log(id, domain)
  let link = `${host}/admin/domains/gift-card/${id}`;
  const reqBody = {domain: domain}
  return fetch(link, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(reqBody),
    },
  );
}
