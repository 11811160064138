export const getWBDListDomains = (host, token, data) => fetch(
    `${host}/admin/${data.type}?take=${data.size}&skip=${data.page}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
    },
  );
export const addWBDListDomain = (host, token, data) => fetch(
    `${host}/admin/${data.type}`,
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(data.data)
    },
);
export const removeWBDListDomain = (host, token, data) => fetch(
    `${host}/admin/${data.type}/${data.domain}`,
    {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        },
);
export const searchWBDListDomain = (host, token, data) => fetch(
    `${host}/admin/${data.type}/search?domain=${data.domain}&take=${data.size}`,
    {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    },
);
export const addBulkWBDListDomain = (host, token, data) => fetch(
    `${host}/admin/${data.type}/bulk`,
    {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${token}` },
        body: data.data
    },
);


