// GET {{host}}/admin/app-messages
// GET {{host}}/admin/app-messages/42a6e081-df7f-47b6-87eb-13f8e12eb4ff
// POST {{host}}/admin/app-messages
// PUT {{host}}/admin/app-messages/b31ea346-3dfa-45e8-a3cf-6ce6a0476ab6
// DELETE {{host}}/admin/app-messages/b3de32b3-b2af-44f6-a962-5b6c69366963

// GET {{host}}/app-messages?version=1.0.3&platform=extOpera&position=popup

export const getAllAppMessages = (host, token) => fetch(
  `${host}/admin/app-messages`,
  {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
  },
);
export const getAppMessage = (host, token, id) => fetch(
  `${host}/admin/app-messages/${id}`,
  {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
  },
);

export const createAppMessage = (host, token, data) => fetch(
  `${host}/admin/app-messages`,
  {
    method: 'POST',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
    body: data
  },
);

export const updateAppMessage = (host, token, id, data) => fetch(
  `${host}/admin/app-messages/${id}`,
  {
    method: 'PUT',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
    body: data
  },
);

export const deleteAppMessage = (host, token, id) => fetch(
  `${host}/admin/app-messages/${id}`,
  {
    method: 'DELETE',
    headers: { 'Authorization': `Bearer ${token}`, "Content-Type": "application/json" },
  },
);
