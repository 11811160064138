export const getHighLoadMode = (host, token) => fetch(
  `${host}/admin/high-load-mode`,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  },
);

export const setHighLoadMode = (host, token, value) => fetch(
  `${host}/admin/high-load-mode`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(value),
  },
);

export const getSmsRestrictions = (host, token)=> fetch(
  `${host}/admin/sms-restrictions`,
  {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
  },
);

export const setSmsRestrictions = (host, token, body)=> fetch(
  `${host}/admin/sms-restrictions`,
  {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    body: JSON.stringify(body),
  },
);
