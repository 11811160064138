export const removeUnmountStorage = () => {
    localStorage.removeItem('removedCoupons');
}

export const getCountShowEntries = (page, func) => {
    const prevStorage = JSON.parse(localStorage.getItem('showEntries') || '{}');
    if (prevStorage && !!prevStorage[page]) {
        func(Number(prevStorage[page]))
        return prevStorage[page]
    }
    return null
}

export const saveCountShowEntries = (page, value) => {
    const prevStorage = JSON.parse(localStorage.getItem('showEntries') || '{}');
    localStorage.setItem('showEntries', JSON.stringify({...prevStorage, [page]: value}))
}

export const getOrderForSort = (name) => {
    return JSON.parse(localStorage.getItem(name));
}
