import ReactDOM from 'react-dom';
// import './sass/bootstrap.css';
import 'adminlte-2-react/src/css/bootstrap.min.css';
import './sass/global.scss';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(App, document.getElementById('root'));

serviceWorker.unregister();
